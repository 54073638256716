import { useState, useEffect } from 'react';
import './App.css';
import db from './firebase';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { query, orderBy, limit } from 'firebase/firestore';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';

function App() {
  const [text, setText] = useState('');
  const [documents, setDocuments] = useState([]);
  const [username, setUsername] = useState('');

  // This useEffect is for setting the username.
  useEffect(() => {
    if (!username) {
      const cookie = jwt(Cookies.get("CF_Authorization"));
      setUsername(cookie["email"].split("@")[0]);
    }
  }, [username]);


  // This useEffect is for setting up the Firestore listener.
  useEffect(() => {
    const q = query(collection(db, 'message_from_writer'), orderBy('time', 'desc'), limit(10));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setDocuments(
        snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.time - b.time));
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const addDocument = async () => {
    try {
      const time = new Date().getTime()
      await addDoc(collection(db, 'message_from_writer'), { username, text, time });
      setText('');
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };



  return (
    <div className="App">
      <header className="App-header">
        <div>
          {documents.map(doc => (
            <p id='Msg' key={doc.id}>{doc.username}: {doc.text}</p>
          ))}
        </div>
        <div className='bottom'>
          <input type="text" value={text} onChange={(e) => setText(e.target.value)} id='boxMsg' />
          <button onClick={addDocument} id='sendMsg'>Send</button>
        </div>
      </header>
    </div>
  );
}

export default App;
