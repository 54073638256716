import { getFirestore } from "firebase/firestore";
import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAKagFfXvNRz8WAYsir6OQyf1aUPnHaxCQ",
    authDomain: "m321-45e68.firebaseapp.com",
    projectId: "m321-45e68",
    storageBucket: "m321-45e68.appspot.com",
    messagingSenderId: "36621518630",
    appId: "1:36621518630:web:8d712cea139607db65e3e7"
  };

/*const firebaseConfig = {
    apiKey: "AIzaSyAGOjY-Cug1jiNcs36YCfhelFDY8bd-TaE",
    authDomain: "eceylan-1a.firebaseapp.com",
    projectId: "eceylan-1a",
    storageBucket: "eceylan-1a.appspot.com",
    messagingSenderId: "463334332769",
    appId: "1:463334332769:web:723f2dddcee0ff10848d20",
    measurementId: "G-9BG305BS6F"
};*/
// const firebaseConfig = {
//     apiKey: "AIzaSyAbt8czxpM_ZJJxE4e5Z1hQCQ7dEtD604Y",
//     authDomain: "dbprobleme.firebaseapp.com",
//     projectId: "dbprobleme",
//     storageBucket: "dbprobleme.appspot.com",
//     messagingSenderId: "742532162852",
//     appId: "1:742532162852:web:8cca9faad3169c6c2e3fb1",
//     measurementId: "G-F4BSZL7SVD"
//   };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db
